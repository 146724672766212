/* Copyright 2023 (Unpublished) Verto Inc. */

@mixin button {
  .btn {
    border-radius: 8px;
    min-height: 52px;

    &.mah-primary {
      background-color: #1f66ef;
      color: #ffffff;
    }

    &.mah-secondary {
      background-color: #b8b9b9;
      color: #ffffff;
    }

    &.mah-default {
      background-color: #f5f6f8;
      color: #909191;
      border: none;
    }

    &.mah-transparent-bkg {
      background-color: transparent;
      color: #2a73ff;
      font-weight: 500;
    }
  }
}
