/* Copyright 2023 (Unpublished) Verto Inc. */

@import '../variables';

@mixin desktop {
  @media (max-width: #{$large-device-width}) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$medium-device-width}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$small-device-width}) {
    @content;
  }
}
@mixin extra-small-mobile {
  @media (max-width: #{$extra-small-device-width}) {
    @content;
  }
}
@mixin extra-extra-small-mobile {
  @media (max-width: #{$extra-extra-small-device-width}) {
    @content;
  }
}
