/* Copyright 2023 (Unpublished) Verto Inc. */

@import './box';
@import './error';

@mixin input {
  input {
    -webkit-appearance: none;
    border: solid 1px #c7c7c7;
    border-radius: 7.2px;
    font-size: 18px;
    line-height: 46px;
    width: 100%;
    padding-left: 15px;

    &.ng-invalid {
      &.ng-touched {
        @include error;
        -webkit-appearance: none;
      }
    }

    &:focus {
      outline: 0;
      @include box-shadow(0 0 0 1pt #1f66ef);
    }
  }

  p-inputMask {
    &.ng-invalid {
      &.ng-touched {
        input {
          @include error;
          -webkit-appearance: none;
        }
      }
    }

    .p-inputtext {
      color: #000000;
      padding: 0 0 0 15px;
      font-family: 'Poppins', sans-serif;
    }
    .p-inputtext:enabled:hover:not(.p-state-error) {
      border-color: #c7c7c7;
    }
  }
}
