/* Copyright 2023 (Unpublished) Verto Inc. */

@import './mixins/input';
@import './mixins/button';
@import 'mixins/responsive';

.mah-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  color: #000000;

  @include input;
  @include button;

  small {
    font-weight: bold;
    color: black;
  }

  div.mah-row {
    width: 100%;
    margin: 6px 0;
  }
}

.mah-flex-row-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  color: #000000;
  justify-content: space-between;

  @include tablet {
    flex-direction: column;
  }

  @include input;
  @include button;

  div.mah-row {
    width: 100%;
    margin: 6px 0;
  }
  div.mah-row-input {
    float: left;
    width: 73%;
    margin: 6px 0;

    @include tablet {
      width: 100%;
    }
  }
  div.mah-row-dropdown {
    float: left;
    width: 20%;
    margin: 6px 0;
    margin-top: 9px;

    @include tablet {
      width: 100%;
    }
  }
}
