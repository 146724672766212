/* Copyright 2023 (Unpublished) Verto Inc. */

@import 'extensions';

@mixin box-shadow($val...) {
  -webkit-box-shadow: $val;
  -moz-box-shadow: $val;
  box-shadow: $val;
}

@mixin container {
  display: block;
  position: relative;
  font-size: 14px;
  padding-left: 30px;
  line-height: 1.3;
  margin-bottom: 10px;
  cursor: pointer;
  @include user-select(none);
}
