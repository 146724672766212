/* Copyright 2023 (Unpublished) Verto Inc. */

//-------------------- Base Colors --------------------//
$body-color: #f5f6f8;
$body-text-color: #555;
$heading-color: #222;

$color-1: #5cbbff;
$color-2: #0f293b;
$color-3: #00a4de;
$color-4: #121921;
$color-5: #ffc107;
$color-6: rgba(92, 187, 255, 0.05);
$color-verto: #1f66ef;

$border-color-2: #ededed;
$border-color-3: #ddd;
$border-color-4: #ccc;
$border-color-5: #ddd;

//-------------------- Line Height Variables --------------------//
$line-height-base: 1.4;
$line-height-large: 1.8;
$line-height-none: 1;

//-------------------- Font Families --------------------//
$font-main: 'Poppins', sans-serif;

//-------------------- Font Sizes --------------------//
$font-size-base: 0.875rem;

//-------------------- Responsive sizes -------------------- //
$large-device-width: 1199px; // Large devices (desktops, less than 1200px)
$medium-device-width: 991px; // Medium devices (tablets, less than 992px)
$small-device-width: 767px; // Small devices (landscape phones, less than 768px)
$extra-small-device-width: 575px; // Extra small devices (portrait phones, less than 576px)
$extra-extra-small-device-width: 360px; // Extra small devices (portrait phones, less than 576px)
